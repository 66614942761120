import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"
import { handleCopy, handleDownload } from "../../helpers/covert-case"

const parent = [
  {
    name: "Tools",
    link: "tools"
  }
]

function PascalCaseText() {
  const [originalText, setOriginalText] = useState('');
  const [textToFind, setTextToFind] = useState('');
  const [textToReplace, setTextToReplace] = useState('');
  const [replacedText, setReplacedText] = useState('');
  const [error, setError] = useState('');
  const charCount =  originalText.replace(/\s/g, '');
  const wordCount = originalText.split(/\s+/).filter(Boolean).length;
  const lineCount = originalText.split('\n').length;
  const [message, setMessage] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  const handleReplace = ({textFieldValue}) => {

    if (!originalText || !textToFind) {
      setError('Please enter the original text and text to find.');
      return;
    }

    const regex = new RegExp(textToFind, 'gi');
    const newText = originalText.replace(regex, textToReplace);
    setReplacedText(newText);
    setError('');
  };
  const handleDownloadClick = () => {
    handleDownload(replacedText);
    setMessage("Download completed!");
    setTimeout(() => setMessage(""), 2000);
  };

  const handleCopyClick = () => {
    handleCopy(replacedText);
    setMessage("Text copied to clipboard!");
    setTimeout(() => setMessage(""), 2000);
  };
  useEffect(() => {
    setIsInputEmpty(originalText === "");
  }, [originalText])


  return (
    <Layout>
      <Seo title="Free Text Replacer Tool" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Replace Text Generator</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="Replace Text Generator" parent={parent} />

          <div className="row align-items-center ">
            <div className="d-lg-flex align-items-center mb-2">
              <div className="d-flex align-items-center gap-2 mb-3 mb-lg-0">
                <label htmlFor='textToFind' className="mb-0">Find:</label>
                <input type='text' id='textToFind' className='form-control me-2' value={textToFind} onChange={e => setTextToFind(e.target.value)}  style={{ height: '35px', width: '200px'}}/>
              </div>
              <div className="d-flex align-items-center gap-2 mb-3 mb-lg-0">
                <label htmlFor='textToReplace' className="mb-0">Replace:</label>
                <input type='text' id='textToReplace' className='form-control' value={textToReplace} onChange={e => setTextToReplace(e.target.value)}  style={{ height: '35px', width: '200px'}}/>
              </div>
              <button type='button' className={`${convertStyled.download_copy_btn} btn text-white btn-primary ms-2 `}  disabled={isInputEmpty} onClick={handleReplace}>Replace</button>
            </div>

            <div className="row">
              <div className="col-lg-6">

                <textarea name=""  value={originalText}  onChange={e => setOriginalText(e.target.value)} className={convertStyled.text_field} id="" cols="30" rows="10" />
              <div className="pt-2">
                <strong>Character Count:</strong> {charCount.length} |{' '}
                <strong>Word Count:</strong> {wordCount} |{' '}
                <strong>Line Count:</strong> {lineCount}

              </div>
              </div>
              <div className="col-lg-6">

                <textarea
                  name="" value={replacedText}  className={`${convertStyled.text_field}`} id="" cols="30" rows="10" />
                <div className=" py-2">
                  <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary   me-1`} onClick={handleDownloadClick} disabled={isInputEmpty} >Download</button>
                  <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary `} onClick={handleCopyClick} disabled={isInputEmpty} >Copy</button>
                </div>
                {message && (<CopiedMessage message={message} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default PascalCaseText





